import { ethers } from "ethers";
import contractInterface from "../contracts/Uppercut.json";
import {
  AllowlistMintNotStartedError,
  PublicMintNotStartedError,
} from "./errors";

export function getReadOnlyContract() {
  let contractAddress = getContractAddress();
  let nodeUrl = process.env.NEXT_PUBLIC_ETHEREUM_NODE_URL;
  let provider = new ethers.providers.JsonRpcProvider(nodeUrl);
  let contract = new ethers.Contract(
    contractAddress,
    contractInterface.abi,
    provider
  );

  return contract;
}

/**
 * Returns the contract address for the current environment. Local environments
 * will have access to a contractAddress.json file so we can reference it.
 * Other environments will have an environment variable set to the
 * corresponding contract address.
 */
export function getContractAddress() {
  if (!process.env.NEXT_PUBLIC_CONTRACT_ADDRESS) {
    let contractAddressFile = require("../contracts/contractAddress.json");
    return contractAddressFile.contractAddress;
  } else {
    return process.env.NEXT_PUBLIC_CONTRACT_ADDRESS;
  }
}

export async function getConnectedContract() {
  const contractAddress = getContractAddress();
  const { ethereum } = window;
  if (!ethereum) return;

  const chainId = await ethereum.request({ method: "eth_chainId" });

  const targetNetworkId =
    "0x" +
    parseInt(process.env.NEXT_PUBLIC_ETHEREUM_NETWORK_ID || "1").toString(16);

  if (chainId !== targetNetworkId) {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: targetNetworkId }],
      });
    } catch (error) {
      console.error(error);
      return;
    }
  }

  let provider = new ethers.providers.Web3Provider(ethereum);
  let signer = provider.getSigner();
  let connectedContract = new ethers.Contract(
    contractAddress,
    contractInterface.abi,
    signer
  );

  return connectedContract;
}

export async function publicMint(
  address: string,
  oqoQuantity: number,
  tngoQuantity: number,
  gnasisQuantity: number,
  setButtonText: (newState: string) => void,
  setTransactionLink: (newState: string) => void
) {
  const connectedContract = await getConnectedContract();
  if (!connectedContract) return;

  const price = await getPublicMintPrice();

  if (!price)
    throw new PublicMintNotStartedError("Public mint has not started!");

  setButtonText("Waiting for wallet approval");

  const transaction = await connectedContract.publicMint(
    address,
    oqoQuantity,
    tngoQuantity,
    gnasisQuantity,
    {
      value: ethers.utils.parseEther(
        (price * (oqoQuantity + tngoQuantity + gnasisQuantity)).toString()
      ),
    }
  );

  setButtonText("Minting... View on Etherscan");

  setTransactionLink(
    `${process.env.NEXT_PUBLIC_ETHERSCAN_URL}/${transaction.hash}`
  );

  await transaction.wait();

  setButtonText("Success!");
}

export async function allowlist(walletAddress: string) {
  let connectedContract = await getConnectedContract();
  if (!connectedContract) return;

  let result = await connectedContract.allowlist(walletAddress);

  return result.toNumber();
}

export async function allowlistMint(
  domosId: 0 | 1 | 2,
  setButtonText: (newState: string) => void,
  setTransactionLink: (newState: string) => void
) {
  let connectedContract = await getConnectedContract();

  if (!connectedContract) return;

  let price = await getAllowlistMintPrice();

  if (!price)
    throw new AllowlistMintNotStartedError("Allowlist mint has not started!");

  setButtonText("Waiting for wallet approval");

  let transaction = await connectedContract.allowlistMint(domosId, {
    value: ethers.utils.parseEther(price.toString()),
  });

  setButtonText("Minting... View on Etherscan");

  setTransactionLink(
    `${process.env.NEXT_PUBLIC_ETHERSCAN_URL}/${transaction.hash}`
  );

  await transaction.wait();

  setButtonText("Success!");
}

export async function calculateTokenURIId(heroId: number): Promise<number> {
  let readOnlyContract = getReadOnlyContract();
  let result = await readOnlyContract.calculateTokenURIId(heroId);
  return result.toNumber();
}

export async function balances(domosId: 0 | 1 | 2) {
  let readOnlyContract = getReadOnlyContract();
  let response = await readOnlyContract.balances(domosId);
  return response;
}

export async function numberOfHeroesPerDomos() {
  let readOnlyContract = getReadOnlyContract();
  let response = await readOnlyContract.numberOfHeroesPerDomos();
  return response;
}

export async function maxHeroesPerWallet() {
  let readOnlyContract = getReadOnlyContract();
  let response = await readOnlyContract.MAX_PER_WALLET();
  return response;
}

export async function getWalletBalance() {
  let { ethereum } = window;
  if (!ethereum) return;

  let provider = new ethers.providers.Web3Provider(ethereum);
  let connectedContract = await getConnectedContract();
  if (!connectedContract) return;

  let response = await connectedContract.balanceOf(
    (
      await provider.listAccounts()
    )[0]
  );

  return response.toNumber();
}

export async function getPublicMintPrice() {
  let readOnlyContract = getReadOnlyContract();
  let saleConfig = await readOnlyContract.saleConfig();

  return parseFloat(ethers.utils.formatEther(saleConfig.publicPrice));
}

export async function getAllowlistMintPrice() {
  let readOnlyContract = getReadOnlyContract();
  let saleConfig = await readOnlyContract.saleConfig();

  return parseFloat(ethers.utils.formatEther(saleConfig.allowListPrice));
}

export function getErrorMessageFromWeb3Error(error: any) {
  if (
    error.message &&
    typeof error.message === "string" &&
    error.message.includes("error=") &&
    error.message.includes("method=")
  ) {
    return JSON.parse(error.message.split("error=")[1].split(", method=")[0])
      .message;
  }
  return error?.data?.message || error?.message || "Something went wrong.";
}
