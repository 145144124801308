import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { Contract } from "ethers";

import { getConnectedContract } from "lib/chain";

async function hitLocalTransferWebhook(
  from: string,
  to: string,
  tokenId: { _hex: string }
) {
  await fetch("/api/transfers", {
    method: "POST",
    headers: { "Content-Type": "application/json" },

    // Structuring this to follow the shape of transaction payloads from
    // QuickNode. See `/api/transfers/index.ts` for formatting.
    body: JSON.stringify({
      args: [from, to, tokenId._hex],
    }),
  });
}

export default function LocalEthListener() {
  // Disabling ESLint so that we can conditionally run hooks only on local.
  /* eslint-disable */

  if (process.env.NEXT_PUBLIC_ETHEREUM_NETWORK_ID !== "31337") return null;

  let session = useSession();
  let [listener, setListener] = useState<Contract | undefined>();

  useEffect(() => {
    if (!session?.data || listener) return;

    getConnectedContract().then((contract) => {
      setListener(contract?.on("Transfer", hitLocalTransferWebhook));
    });
  }, [session, listener]);

  return <></>;
}
